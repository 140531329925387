<template>
<div>
    <div :class="{ 'modal-backdrop': modal }" v-if="footprint" @click.prevent="closeModal"></div>
    <div :class="{ 'modal modal-slide fade': modal, show: footprint !== [] && footprint }" class="record-modal" v-if="footprint" id="record_modal">

        <div :class="{ 'modal-dialog': modal }">
            <div class="modal-header" v-if="modal">
                <div class="carbon-footprint-date-range-heading">
                    Detailed results for the period:<br>
                    <h3 class="h3 mt-3 mb-5 mr-3">{{ footprint.start_date_string }} - {{ footprint.end_date_string }}</h3>

                </div>
                <a class="modal-close d-flex ml-5 btn btn-info align-items-center" href="#" @click.prevent="closeModal">
                    <span class="mr-2">Close</span>
                    <i class="fal fa-times"></i>
                </a>
            </div>

            <div :class="{ 'modal-content': modal }">
                <div class="footprintresultsdetails">
                <div class="container-fluid bg-nice-black py-3 mb-0 footprint-report-overall-chart">
                <div class="container">
                    <div class="d-flex" style="align-items: center; justify-content: space-around">
                        <div style="min-width: 380px;" :style="{ 'width': (footprint['has_cost_data'] ? '25vw':'45vw') }">
                            <div class="total_footprint_value text-center">
                                <h1 class="display-2 mt-1 mb-0 text-white font-weight-bold">{{ formatNumber(footprint['totalEmissions']/1000,2)  }}</h1>
                                <span class="text-white">Tonnes CO<sub>2</sub>e</span>
                            </div>
                            <overall-footprint-chart
                                :data="JSON.stringify(footprint['overallChartData'])"
                                :dataset_labels="footprint['labels_formatted']"
                                :color_scheme="footprint['colours_formatted']"
                                :show_waste_disposal="show_waste_disposal"
                            ></overall-footprint-chart>
                        </div>
                        <div class="pl-5 pr-5 pt-3 pb-2 bg-white">

                            <overall-table
                                :total_energy=" formatNumber(footprint['totalEnergy']/1000,2) "
                                :total_transport=" formatNumber(footprint['totalTransport']/1000,2) "
                                :total_commute=" formatNumber(footprint['totalCommute']/1000,2) "
                                :total_water=" formatNumber(footprint['totalWater']/1000,2) "
                                :total_waste=" formatNumber(footprint['totalWaste']/1000,2) "
                                :total_generated=" formatNumber(footprint['totalGenerated']/1000,2) "
                                :total_biomass=" formatNumber(footprint['totalBiomass']/1000,2) "
                                :total="formatNumber(footprint['totalEmissions']/1000,2)"
                                :n_trees="formatNumber( (footprint['totalEmissions'] / 7),2)"
                                :cost_energy=" footprint['costs'] && footprint['costs']['energy'] ? formatNumber(footprint['costs']['energy'],0) : '-' "
                                :cost_transport=" footprint['costs'] && footprint['costs']['transport'] ? formatNumber(footprint['costs']['transport'],0) : '-' "
                                :cost_water=" footprint['costs'] && footprint['costs']['water'] ? formatNumber(footprint['costs']['water'],0) : '-' "
                                :cost_waste=" footprint['costs'] && footprint['costs']['waste'] ? formatNumber(footprint['costs']['waste'],0) : '-' "
                                :cost_total=" footprint['costs'] && footprint['total_cost'] ? formatNumber(footprint['total_cost'],0) : '-' "
                                :has_any_costs="!!footprint['has_cost_data']"
                                :show_waste_disposal="show_waste_disposal"
                            >
                            </overall-table>
                        </div>
                    </div>
                </div>
                </div>

                <div class="container footprint-report-introtext">
                    <div class="row py-5 align-items-center">

                        <div class="col-7">

                            <p class="lead pr-5">The summary above shows the total <em>Carbon Dioxide Equivalent</em> (CO<sub>2</sub>e) for your business based on the data you have entered.</p>


                        </div>
                        <div class="col-5">
                            <p><strong>CO<sub>2</sub>e</strong> (also written as carbon dioxide equivalent, CO2 equivalent or CO2eq) is a measure that is used to compare emissions from various greenhouse gases on the basis of their GWP (global warming potential) by converting amounts of other gases to the equivalent amount of Carbon Dioxide (CO<sub>2</sub>).</p>
                        </div>
                    </div>
                </div>

                <div v-if="footprint.show_intensity_factors">
                    <div class="container-fluid bg-green py-3 mb-5" >
                        <div class="container pt-4 pb-4">
                            <h2 class="h1 mb-3 text-white">Carbon Intensity</h2>

                            <div class="" v-if="footprint.intensity_factors.length">
                                <p class="lead text-white">Carbon intensity ratios help you compare your carbon footprint with other businesses or sectors. They are calculated by dividing your total carbon emissions by a metric that is comparable between businesses, for example the number of staff (Full Time Equivalent) or your turnover (£ million turnover).</p>

                                <div class="row carbon-intensity-factors">

                                    <div class="col-4 text-center" v-for="(factor, index) in footprint.intensity_factors">
                                        <div class="carbon-intensity-factor">

                                            <h4 class="carbon-intensity-factor-title"><small><em>by</em></small> {{ factor.title }}</h4>

                                            <span class="fa-stack fa-5x">
                                              <i class="fas fa-square fa-stack-2x" style="color: rgb(88 88 88 / 32%);"></i>
                                              <i :class="formatIntensityFactorClass(factor)"></i>
                                            </span>

                                            <div class="total_footprint_value intensity_value text-center">
                                                <h1 class="display-2 mt-1 mb-0 text-white font-weight-bold">

                                                    {{ formatIntensityValue(factor.intensity_value_string) }}

                                                </h1>
                                                <span class="text-white">{{ factor.intensity_unit_string }} CO<sub>2</sub>e per {{ factor.unit_description }}</span>

                                                <i v-if="factor.title==='Annual Turnover'"
                                                    class="fad fa-info-circle"
                                                   style="position: relative; display: inline-block;"
                                                   v-tooltip.bottom="convertToPerMillion(factor.intensity)"></i>

                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <p class="lead text-white">Carbon intensity ratios are useful because they allow:</p>
                                <ul class="lead text-white">
                                    <li>Comparison over time</li>
                                    <li>Comparison across different organisation sectors and products.</li>
                                </ul>
                                <p class="lead text-white">They are particularly helpful in demonstrating carbon reductions in a growing organisation.</p>
                            </div>

                            <div class="row" v-if="!footprint.intensity_factors.length">
                                <div class="col-10">
                                    <p class="lead text-white">We can't find any information in this footprint to use to calculate your carbon intensity figures. You may want to <a href="#" class="">update your answers</a>, ensuring that any data you enter is relevant to the period of time that this carbon audit relates to.</p>
                                </div>
                                <div class="col-8 mt-4">
                                    <p class="text-white">Carbon Intensity is a way of looking at your footprint that helps to compare it to other businesses or sectors.</p>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

                <div class="container-fluid bg-gray-600 py-3 mb-5" >
                    <div class="container pt-4 pb-4">
                        <h2 class="h1 mb-3 text-white">Carbon Footprint Breakdown</h2>
                        <p class="lead text-white">The charts and tables below show your carbon emissions across the various activities within your business. Activities with the greatest emissions are a good place to start your carbon reduction actions. </p>
                        <p class="text-white"><a :href="faqurl" target="_blank" class="btn btn-link text-white"><i class="fa fa-info-circle fal"></i> You can read more detail about how these numbers are generated and what they mean on the FAQs page</a></p>

                    </div>
                </div>

                <div class="container footprint-report-breakdown pb-5 mb-5">
                    <tabs :options="{ useUrlFragment: false }" cache-lifetime="0">
                        <tab name="Energy audit" prefix="<i class='fad fa-plug fa-2x text-energy-footprint mr-3'></i>">

                            <div class="d-block position-relative mb-4" v-if="footprint['totalEnergy']">
                                <chart
                                :series="[{'data': footprint['energyDetailsChartData']['values']}]"
                                :dataset_labels="footprint['energyDetailsChartData']['labels']"
                                :color_scheme="footprint['backgroundColours']"
                                :chart_url="footprint['charturl']"
                                chart_type="donut"
                                chart_id="totalEnergyChart"
                                ></chart>
                            </div>

                            <table class="table table-striped" v-if="footprint['totalEnergy']">
                                <thead>
                                <tr>
                                    <th style="border-top: none;" class="text-left">Energy type</th>
                                    <th style="border-top: none;" class="text-left">CO<sub>2</sub>e <small>(Tonnes)</small></th>
                                    <th style="border-top: none;" class="text-left">Usage</th>
                                </tr>
                                </thead>

                                <tbody>

                                <tr v-for="(energychartdatalabel,index) in footprint['energyDetailsChartData']['labels']">
                                    <td><strong>{{ energychartdatalabel }}</strong></td>
                                    <td>{{ formatNumber(footprint['energyDetailsChartData']['values_kg'][index]/1000,2) }}</td>
                                    <td>{{ formatNumber(footprint['energyDetailsChartData']['usage'][index],0) }} {{footprint['energyDetailsChartData']['units'][index]}}</td>
                                </tr>


                                </tbody>
                                <tfoot>

                                <tr v-if="footprint['totalBiomass'] > 0">
                                    <td colspan="3" class="text-left pt-3">
                                        Your Biomass usage came to <span>{{ formatNumber(footprint['totalBiomass']/1000,2) }}</span> Tonnes CO<sub>2</sub>e, but this is not included in your overall carbon footprint <i class="fad fa-info-circle" style="position: relative; display: inline-block;" v-tooltip.bottom="'NB Current guidelines for carbon reporting exclude any biomass usage from your overall footprint.<br> ' +
                    'It is still useful to record this information as the guidance may change in the future'"></i>
                                    </td>
                                </tr>

                                <tr>
                                    <th scope="row">Total for Energy</th>
                                    <td>{{ formatNumber(footprint['energyDetailsChartData']['total'],2) }} Tonnes CO<sub>2</sub>e</td>
                                </tr>
                                </tfoot>

                            </table>

                            <p v-if="!footprint['totalEnergy']">No energy usage data was submitted</p>
                        </tab>


                        <tab name="Transport audit" prefix="<i class='fad fa-car fa-2x  text-transport-footprint mr-3'></i>">

                            <div class="col-12" v-if="footprint['totalTransport']">
                                <div class="d-block position-relative mb-4">
                                    <chart
                                    :series="[{'data': footprint['vehicleDetailsChartData']['values']}]"
                                    :dataset_labels="footprint['vehicleDetailsChartData']['labels']"
                                    :color_scheme="footprint['backgroundColours']"
                                    :chart_url="footprint['charturl']"
                                    chart_type="donut"
                                    chart_id="totalTransportChart"
                                    ></chart>
                                </div>
                            </div>

                            <table class="table table-striped" v-if="footprint['totalTransport']">
                                <thead>
                                <tr>
                                    <th style="border-top: none;" class="text-left">Transport</th>
                                    <th style="border-top: none;" class="text-left">CO<sub>2</sub>e <small>(Tonnes)</small></th>
                                    <th style="border-top: none;" class="text-left">Distance <small>(Km)</small><sup>*</sup></th>
                                </tr>
                                </thead>

                                <tbody>

                                <tr v-for="(transportchartdatalabel,index) in footprint['vehicleDetailsChartData']['labels']">
                                    <td><strong>{{ transportchartdatalabel }}</strong></td>
                                    <td>{{ formatNumber(footprint['vehicleDetailsChartData']['values_kg'][index]/1000,2) }}</td>
                                    <td>{{ formatNumber(footprint['vehicleDetailsChartData']['usage'][index],0) }}</td>
                                </tr>

                                </tbody>
                                <tfoot>
                                <tr>
                                    <td colspan="2" style="padding: 0; font-size: .85rem;"><small class="text-right" style="text-align: right">* distances are converted to Kilometres prior to calculating CO2E. You enter the data in miles.</small></td>
                                </tr>
                                </tfoot>
                                <tfoot>
                                <tr>
                                    <th scope="row">Total for Transport</th>
                                    <td>{{ formatNumber(footprint['vehicleDetailsChartData']['total'],2) }} Tonnes CO<sub>2</sub>e</td>
                                </tr>
                                </tfoot>

                            </table>

                            <p v-if="!footprint['totalTransport']">No transport data was submitted</p>
                        </tab>


                        <tab name="Water audit" prefix="<i class='fad fa-water fa-2x text-water-footprint mr-3'></i>">

                            <table class="table table-striped" v-if="footprint['totalWater']">
                                <thead>
                                <tr>
                                    <th style="border-top: none;" class="text-left">Aspect</th>
                                    <th style="border-top: none;" class="text-left">CO<sub>2</sub>e <small>(Tonnes)</small></th>
                                    <th style="border-top: none;" class="text-left">Usage <small>(m<sup>3</sup>)</small></th>
                                </tr>
                                </thead>

                                <tbody>

                                <tr v-for="(waterchartdatalabel,index) in footprint['waterDetailsChartData']['labels']">
                                    <td><strong>{{ waterchartdatalabel }}</strong></td>
                                    <td>{{ formatNumber(footprint['waterDetailsChartData']['values_kg'][index]/1000,2) }}</td>
                                    <td>{{ formatNumber(footprint['waterDetailsChartData']['usage'][index],0) }}</td>
                                </tr>

                                </tbody>
                                <tfoot>
                                <tr>
                                    <th scope="row">Total for Water</th>
                                    <td>{{ formatNumber(footprint['waterDetailsChartData']['total'],2) }} Tonnes CO<sub>2</sub>e</td>
                                </tr>
                                </tfoot>

                            </table>

                            <p v-if="!footprint['totalWater']">No water usage data was submitted</p>
                        </tab>

                        <tab v-if="show_waste_disposal" name="Waste audit" prefix="<i class='fad fa-dumpster fa-2x text-waste-footprint mr-3'></i>">

                            <div class="d-block position-relative mb-4" v-if="footprint['totalWaste']">
                                <chart
                                    :series="[{'data': footprint['wasteDetailsChartData']['values']}]"
                                    :dataset_labels="footprint['wasteDetailsChartData']['labels']"
                                    :color_scheme="footprint['backgroundColours']"
                                    :chart_url="footprint['charturl']"
                                    chart_type="donut"
                                    chart_id="totalWasteChart"
                                ></chart>
                            </div>

                            <table class="table table-striped" v-if="footprint['totalWaste']">
                                <thead>
                                <tr>
                                    <th style="border-top: none;" class="text-left">Waste stream</th>
                                    <th style="border-top: none;" class="text-left">Recycled?</th>
                                    <th style="border-top: none;" class="text-left">CO<sub>2</sub>e <small>(Tonnes)</small></th>
                                    <th style="border-top: none;" class="text-left">Volume <small>(Tonnes)</small></th>
                                </tr>
                                </thead>

                                <tbody>

                                <tr v-for="(wastechartdatalabel,index) in footprint['wasteDetailsChartData']['labels']">
                                    <td><strong>{{ wastechartdatalabel }}</strong></td>
                                    <td>{{ footprint['wasteDetailsChartData']['recycling_labels'][index] }}</td>
                                    <td>{{
                                            footprint['wasteDetailsChartData']['values'][index] < 0.005
                                                ? `${formatNumber(footprint['wasteDetailsChartData']['values_kg'][index],2)} Kg`
                                                : formatNumber(footprint['wasteDetailsChartData']['values_kg'][index]/1000,2) }}
<!--                                        <strong v-if="footprint['wasteDetailsChartData']['values'][index] < 0.005"><em>Kg</em></strong>-->
                                        <i v-if="footprint['wasteDetailsChartData']['values'][index] < 0.005"
                                           class="fad fa-info-circle"
                                           style="position: relative; display: inline-block;"
                                           v-tooltip.bottom="'The CO2E value is too small to display in tonnes, so it is shown in Kilograms'"></i>
                                    </td>
                                    <td>{{ formatNumber(footprint['wasteDetailsChartData']['usage'][index],3) }}</td>
                                </tr>

                                </tbody>
                                <tfoot>
                                <tr>
                                    <th scope="row">Total for Waste Disposal</th>
                                    <td>{{ formatNumber(footprint['wasteDetailsChartData']['total'],2) }} Tonnes CO<sub>2</sub>e</td>
                                </tr>
                                </tfoot>

                            </table>

                            <p v-if="!footprint['totalWaste']">No waste disposal data was submitted</p>
                        </tab>

                        <tab name="Energy exported" prefix="<i class='fad fa-battery-half fa-2x text-generated-footprint mr-3'></i>">

                            <div class="col-12" v-if="footprint['totalGenerated']">
                                <div class="d-block position-relative mb-4">
                                    <chart
                                    :series="[{'data': footprint['generationDetailsChartData']['values']}]"
                                    :dataset_labels="footprint['generationDetailsChartData']['labels']"
                                    :color_scheme="footprint['backgroundColours']"
                                    :chart_url="footprint['charturl']"
                                    chart_type="donut"
                                    chart_id="totalGeneratedChart"
                                    ></chart>
                                </div>
                            </div>

                            <table class="table table-striped" v-if="footprint['totalGenerated']">
                                <thead>
                                <tr>
                                    <th style="border-top: none;" class="text-left">Energy type</th>
                                    <th style="border-top: none;" class="text-left">CO<sub>2</sub>e <small>(Tonnes)</small></th>
                                    <th style="border-top: none;" class="text-left">Exported <small>(kWh)</small></th>
                                </tr>
                                </thead>

                                <tbody>

                                <tr v-for="(generationchartdatalabel,index) in footprint['generationDetailsChartData']['labels']">
                                    <td><strong>{{ generationchartdatalabel }}</strong></td>
                                    <td>{{ formatNumber(footprint['generationDetailsChartData']['values_kg'][index]/1000,2) }}</td>
                                    <td>{{ formatNumber(footprint['generationDetailsChartData']['usage'][index],0) }}</td>
                                </tr>

                                </tbody>
                                <tfoot>
                                <tr>
                                    <th scope="row">Total exported to the grid</th>
                                    <td>{{ formatNumber(footprint['generationDetailsChartData']['total'],2) }} Tonnes CO<sub>2</sub>e</td>
                                </tr>
                                </tfoot>
                            </table>

                            <p v-if="!footprint['totalGenerated']">No electricity generation data was submitted</p>
                        </tab>

                    </tabs>
                </div>
                </div>
            </div>
        </div>
    </div>

</div>
</template>

<script>

import OverallFootprintChart from "./OverallFootprintChart";
import OverallTable from "./OverallTable";
import Chart from "../charts/Chart";
import {Tabs, Tab} from 'vue-tabs-component';
import _ from 'lodash';

export default {
    name: "FootprintReportDetail",
    props: {
        footprint_source: { type: [Array,Object], default: () => [], },
        modal: { type: [Boolean], default: true, },
        show_waste_disposal: {type: Boolean, default: false },
        faqurl: { type: String },
    },
    components: {
        OverallFootprintChart,
        OverallTable,
        Chart,
        Tabs,
        Tab,
    },
    data() {
        return {
            is_legacy: false,
            footprint: []
        }
    },
    methods: {
        formatNumber(value,n_decimals) {
           let flValue = parseFloat(value);
           return flValue.toLocaleString('en-GB', {minimumFractionDigits: n_decimals, maximumFractionDigits: n_decimals});
        },
        array_sum(arr) {
            return _.sum(arr);
        },
        closeModal() {
            document.body.style.overflow = 'initial';
            this.$emit('footprint-detail-modal-close');
            this.footprint = false;
        },
        formatIntensityValue(value) {
            if(value < 0.2) {
                return this.formatNumber(value,3);
            }
            return this.formatNumber(value,1);
        },
        convertToPerMillion(intensity) {

            let tonnesPmSrc = (intensity / 1000000) / 1000,
                tonnesPerMillion = this.formatNumber(tonnesPmSrc,10);
            return `Equivalent to ${tonnesPerMillion} Tonnes CO<sub>2</sub>e / &pound;M`;
        },
        formatIntensityFactorClass(factor) {
            return `fad ${factor.icon} fa-stack-1x fa-inverse`;
        }
    },
    beforeMount() {
        this.footprint = this.footprint_source;
    },
    mounted() {

        if(this.footprint && this.modal) {
            document.body.style.overflow = 'hidden';
        }
        else {
            document.body.style.overflow = 'initial';
        }
    }
}
</script>

<style scoped>
.modal {
    z-index: 9999;
    width: auto;
}
.modal.show {
    display: block;
}
.modal-backdrop {
    z-index: 9998;

    position: fixed;
    top: 0;
    right: 0;
    height: 100% !important;
    left: 0;
    background: #34404a;
    opacity: .5;
    cursor: pointer;
}

.modal-content {
    box-shadow: 0 1px 6px rgba(0, 0, 0, 0.09);
    border-radius: 0;
}
.modal-header {
    position: relative;
    padding: 1.25rem 1.5625rem 1.5625rem 1.5625rem;
    background: #509E02;
    color: #fff;
}
.modal-footer {
    padding: 1.25rem 1.5625rem;
}
.carbon-footprint-date-range-heading .h3 {
    margin: 0 !important;
}
.modal-header .close, .modal-slide .close {
    position: absolute;
    top: 50%;
    right: 1.5625rem;
    margin: 0;
    padding: 0;
    line-height: 1.2;
    transform: translate(0, -50%);
}
@media (min-width: 576px) {
    .modal-content {
        box-shadow: 0 1px 6px rgba(0, 0, 0, 0.09);
    }
    .modal-sm .modal-dialog {
        max-width: 22.5rem;
    }
}
@media (min-width: 992px) {
    .modal-lg .modal-dialog {
        max-width: 50rem;
    }
}
.modal-top .modal-dialog {
    margin-top: 0;
}
.modal-top .modal-content {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
}
.modal-slide, .modal-slide .modal {
    overflow: hidden !important;
    padding: 0 !important;
}
.modal-slide .modal-dialog {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: auto;
    margin: 0;
    max-width: none;
}
@media (min-width: 576px) {
    .modal-slide .modal-dialog {
        width: 60rem;
    }
}
.modal-slide .modal-content {
    overflow: auto;
    padding-top: 1.2 * '16px' 1.25rem;
    padding-bottom: 1.2 * '16px' 1.25rem;
    height: 100%;
    border-radius: 0;
}
.modal-slide .modal-body {
    flex-grow: 0;
    margin: auto 0;
    padding-top: 0;
    padding-bottom: 0;
}
.modal-slide .close {
    top: 0.625rem;
    z-index: 10;
    transform: none;
}
.modal.fade .modal-dialog {
    transform: translateY(150px) scale(0.8);
}
.modal.show .modal-dialog {
    transform: translateY(0) scale(1);
}
.modal-top.fade .modal-dialog, .modal-top .modal.fade .modal-dialog {
    transform: translateY(-100%);
}
.modal-top.show .modal-dialog, .modal-top .modal.show .modal-dialog {
    transform: translateY(0);
}
.modal-slide.fade .modal-dialog, .modal-slide .modal.fade .modal-dialog {
    transform: translateX(100%);
}
.modal-slide.show .modal-dialog, .modal-slide .modal.show .modal-dialog {
    transform: translateX(0) !important;
}
.modal-fill-in.fade .modal-dialog, .modal-fill-in .modal.fade .modal-dialog {
    transform: scale(0.5, 0.5);
}
.modal-fill-in.show .modal-dialog, .modal-fill-in .modal.show .modal-dialog {
    transform: scale(1, 1);
}

</style>

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "pt-3 footprint-list-wrap" },
        [
          _vm.isLoading
            ? _c("loading", { class: "footprint-loader" })
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "pt-3", attrs: { id: "footprintresults" } },
            [
              _vm.footprint !== null && !_vm.footprint.is_legacy
                ? _c("footprint-report-detail", {
                    attrs: {
                      footprint_source: _vm.footprint,
                      faqurl: "faqurl",
                      show_waste_disposal: _vm.show_waste_disposal,
                    },
                    on: {
                      "footprint-detail-modal-close": _vm.closeFootprintDetail,
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.footprint !== null &&
              (_vm.footprint.is_legacy || _vm.footprint.has_error)
                ? _c("div", { staticClass: "container" }, [_vm._m(0)])
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "default-content" },
                [
                  _vm._t("introtext", function () {
                    return [
                      _c("p", [
                        _vm._v(
                          "Choose an audit from the list below to see detailed results (you won't leave this page)."
                        ),
                      ]),
                    ]
                  }),
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "card-deck" },
      _vm._l(_vm.allfootprints, function (eachfootprint) {
        return _c(
          "div",
          {
            key: eachfootprint.id,
            staticClass: "col-6 col-md-4 col-xl-3 px-0 mx-0 mb-4",
          },
          [
            _c(
              "a",
              {
                staticClass: "footprint-link",
                attrs: { href: "#" },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.getResults(eachfootprint.id)
                  },
                },
              },
              [
                _c(
                  "div",
                  { staticClass: "card p-3 ml-2 mr-2 footprint-card" },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "btn btn-link text-left d-flex footprint-card-show-details",
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "mr-2 footprint-card-dates" },
                          [
                            _c("strong", [
                              _vm._v(
                                _vm._s(eachfootprint.start_date_string) + " -"
                              ),
                            ]),
                            _c("br"),
                            _vm._v(
                              " " +
                                _vm._s(eachfootprint.end_date_string) +
                                "\n                    "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _vm._m(1, true),
                      ]
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "footprint-total-wrap" }, [
                      _c("div", { staticClass: "footprint-total-inner" }, [
                        _c("i", {
                          staticClass:
                            "fad fa-shoe-prints mr-2 footprint-total-icon",
                        }),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "whitespace-nowrap footprint-total" },
                          [
                            _vm._v(
                              "\n                        " +
                                _vm._s(eachfootprint.total_emissions_string) +
                                " "
                            ),
                            _c("span", { staticClass: "tonnes-label" }, [
                              _vm._v("Tonnes"),
                            ]),
                          ]
                        ),
                      ]),
                    ]),
                  ]
                ),
              ]
            ),
          ]
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "row py-4 align-items-center" }, [
      _c("div", { staticClass: "col-7" }, [
        _c("p", { staticClass: "lead pr-5" }, [
          _vm._v(
            "Your carbon audit results cannot be shown for this set of data."
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-5" }, [
        _c("p", [
          _vm._v(
            "In July of 2022 we updated the carbon audit section of the application to a more complete and user-friendly design. It collects more refined data and uses the most recent carbon factor figures from the government."
          ),
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "Unfortunately, this does mean that your old data is not compatible with the reporting format required."
          ),
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "We do still hold a copy of this information, so if you really need to look at it please let us know ("
          ),
          _c("em", [
            _vm._v(
              'it isn\'t a "ten minute job" though, so please consider whether it is absolutely necessary'
            ),
          ]),
          _vm._v(")."),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "ml-auto text-lg" }, [
      _c("i", { staticClass: "fad fa-search-plus footprint-expand-icon" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
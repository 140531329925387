<template>
    <div>
        <table class="table mb-0">
            <thead class="border-top-0">
            <tr>

                <th class="border-top-0 text-right" colspan="3">Total Carbon Equivalent <small>(tonnes)</small></th>
                <th v-if="has_any_costs" class="border-top-0 text-right">Cost <small>&pound;</small></th>
            </tr>
            </thead>
            <tbody>
            <tr>
                <td class="align-middle"><i class="fad fa-plug fa-2x text-energy-footprint"></i></td>
                <th scope="row" class="align-middle">Energy</th>
                <td class="align-middle text-right"><h3>{{ total_energy }}</h3></td>
                <td v-if="has_any_costs" class="align-middle text-right"><span v-if="cost_energy!=='-'" class="d-inline small">&pound;</span><span class="d-inline small"> {{cost_energy}}</span></td>
            </tr>
            <tr>
                <td class="align-middle"><i class="fad fa-car fa-2x  text-transport-footprint"></i></td>
                <th scope="row" class="align-middle">Transport</th>
                <td class="align-middle text-right"><h3>{{ total_transport }}</h3></td>
                <td v-if="has_any_costs" class="align-middle text-right"><span v-if="cost_transport!=='-'" class="d-inline small">&pound;</span><span class="d-inline small"> {{cost_transport}}</span></td>
            </tr>
            <tr v-if="total_commute > 0">
                <td class="align-middle"><i class="fad fa-car-bus fa-2x  text-transport-footprint"></i></td>
                <th scope="row" class="align-middle">Commuting</th>
                <td class="align-middle text-right"><h3>{{ total_commute }}</h3></td>
                <td v-if="has_any_costs" class="align-middle text-right"></td>
            </tr>
            <tr>
                <td class="align-middle"><i class="fad fa-water fa-2x text-water-footprint"></i></td>
                <th scope="row" class="align-middle">Water</th>
                <td class="align-middle text-right"><h3>{{ total_water }}</h3></td>
                <td v-if="has_any_costs" class="align-middle text-right"><span v-if="cost_water!=='-'" class="d-inline small">&pound;</span><span class="d-inline small"> {{cost_water}}</span></td>
            </tr>
            <tr v-if="show_waste_disposal">
                <td class="align-middle"><i class="fad fa-dumpster fa-2x text-waste-footprint"></i></td>
                <th scope="row" class="align-middle">Waste</th>
                <td class="align-middle text-right"><h3>{{ total_waste }}</h3></td>
                <td v-if="has_any_costs" class="align-middle text-right"><span v-if="cost_waste!=='-'" class="d-inline small">&pound;</span><span class="d-inline small"> {{cost_waste}}</span></td>
            </tr>
            </tbody>

            <tbody v-if="total_generated > 0">
            <tr>
                <td class="align-middle"><i class="fad fa-battery-half fa-2x text-generated-footprint"></i></td>
                <th scope="row" class="align-middle">Electricity exported</th>
                <td class="align-middle text-right"><h3 class="text-generated-footprint">- {{ total_generated }}</h3></td>
                <td v-if="has_any_costs" class="align-middle text-right"></td>
            </tr>
            </tbody>

            <tfoot>


            <tr>
                <td colspan="2" class="text-right align-middle">
                    Total CO<sub>2</sub>e <i class="fad fa-info-circle"
                                             style="position: relative; display: inline-block;"
                                             v-tooltip.bottom="'Carbon equivalent, shown in tonnes'"></i>
                </td>
                <td class=" align-middle text-right">
                    <h1>{{ total }}</h1>
                </td>
                <td v-if="has_any_costs" class="align-middle text-right">
                    <span v-if="cost_total!=='-'" class="d-inline small">&pound;</span><span class="d-inline small">{{ cost_total }}</span>
                </td>
            </tr>


            <tr v-if="total_biomass > 0">
                <td :colspan="has_any_costs ? 4 : 3" class="text-right small">
                    Your Biomass usage came to <span>{{total_biomass}}</span> CO<sub>2</sub>e, but this is not included in your overall carbon footprint  <i class="fad fa-info-circle" style="position: relative; display: inline-block;" v-tooltip.bottom="'NB Current guidelines for carbon reporting exclude any biomass usage from your overall footprint.<br> ' +
                    'It is still useful to record this information as the guidance may change in the future'"></i>
                </td>
            </tr>

            </tfoot>



        </table>

    </div>

</template>
<script>
    export default {
        name: 'OverallTable',
        props: {
            total_energy: 0,
            total_transport: 0,
            total_commute: 0,
            total_water: 0,
            total_waste: 0,
            total_generated: 0,
            total_biomass: 0,
            total: 0,
            n_trees: 0,
            cost_energy: 0,
            cost_transport: 0,
            cost_water: 0,
            cost_waste: 0,
            cost_total: 0,
            has_any_costs: false,
            show_waste_disposal: {type: Boolean, default: false },
        }
    }
</script>

<template>
    <div>
        <div class="row">
            <div class="pt-3 footprint-list-wrap">
                <loading v-if="isLoading" :class="'footprint-loader'"></loading>
                <div id="footprintresults" class="pt-3">

                    <footprint-report-detail
                        v-if="footprint !== null && !footprint.is_legacy"
                        :footprint_source="footprint"
                        faqurl="faqurl"
                        @footprint-detail-modal-close="closeFootprintDetail"
                        :show_waste_disposal="show_waste_disposal"
                    ></footprint-report-detail>

                    <div class="container" v-if="footprint !== null && (footprint.is_legacy || footprint.has_error)">
                        <div class="row py-4 align-items-center">

                            <div class="col-7">

                                <p class="lead pr-5">Your carbon audit results cannot be shown for this set of data.</p>


                            </div>
                            <div class="col-5">
                                <p>In July of 2022 we updated the carbon audit section of the application to a more complete and user-friendly design. It collects more refined data and uses the most recent carbon factor figures from the government.</p>
                                <p>Unfortunately, this does mean that your old data is not compatible with the reporting format required.</p>
                                <p>We do still hold a copy of this information, so if you really need to look at it please let us know (<em>it isn't a "ten minute job" though, so please consider whether it is absolutely necessary</em>).</p>
                            </div>
                        </div>
                    </div>



                    <div class="default-content">
                        <slot name="introtext">
                        <p>Choose an audit from the list below to see detailed results (you won't leave this page).</p>
                        </slot>
                    </div>


                </div>
            </div>
        </div>
        <div class="card-deck">

            <div class="col-6 col-md-4 col-xl-3 px-0 mx-0 mb-4" v-for="eachfootprint in allfootprints" :key="eachfootprint.id">
                <a href="#" class="footprint-link" @click.prevent="getResults(eachfootprint.id)">
                <div class="card p-3 ml-2 mr-2 footprint-card">
                    <div class="btn btn-link text-left d-flex footprint-card-show-details">
                        <div class="mr-2 footprint-card-dates">
                            <strong>{{ eachfootprint.start_date_string }} -</strong><br> {{ eachfootprint.end_date_string }}
                        </div>
                        <div class="ml-auto text-lg"><i class="fad fa-search-plus footprint-expand-icon"></i></div>
                    </div>
                    <div class="footprint-total-wrap">
                        <div class="footprint-total-inner">
                        <i class="fad fa-shoe-prints mr-2 footprint-total-icon"></i>
                        <div class="whitespace-nowrap footprint-total">
                            {{ eachfootprint.total_emissions_string }} <span class="tonnes-label">Tonnes</span>
                        </div>
                        </div>
                    </div>
                </div>
                </a>
            </div>
        </div>

    </div>
</template>

<script>
import _ from 'lodash';
import FootprintReportDetail from "./FootprintReportDetail";
import Loading from "../LoadingComponent";

export default {
    name: "FootprintReportChooser",
    props: {
        footprints: { type: [Array, Object], default: () => [], },
        reporturl: { type: String },
        faqurl: {type: String},
        show_waste_disposal: {type: Boolean, default: false },
    },
    data() {
        return {
            footprint: null,
            allfootprints: [],
            isLoading: false
        }
    },
    components: {
        FootprintReportDetail,
        Loading
    },
    created() {

        // Sort the footprints as they don't respect the order they come in...
        this.allfootprints = _.orderBy(this.footprints, 'sort_by_date_source','desc');

        // Register the function for loading results
        this.getResults = _.debounce(this.loadResults, 500)
    },
    methods: {
        loadResults: function (footprint_id) {
            this.isLoading = true;
            this.footprint = null;
            axios.get(this.reporturl, {
                params: {
                    footprint: footprint_id
                }
            })
            .then((response) => {
                this.isLoading = false;
                this.footprint = response.data;
                //this.$forceUpdate();
            });

        },

        closeFootprintDetail() {
            this.isLoading = false;
            this.footprint = null;
        }
    }
}
</script>

<style scoped>

.footprint-list-wrap {
    position: relative;
}

.sticky-top {
    position: sticky;
    top: 85px;
    z-index: 1020;
}
.rounded-lg {
    border-radius: 1.5rem !important;
}

.default-content {
    margin: 0 0 2.5rem;
}

.tonnes-label {
    font-size: 0.475em;
    font-weight: 400;
}
.whitespace-nowrap {
    white-space: nowrap;
}
.footprint-card {
    transition: all 90ms ease-in-out;
    height: 100%;
    margin-right: 20px !important;
    border: 3px solid #e1e1e1;

}
.footprint-card:hover {
    background: #e1e1e1;
    box-shadow: 0 0.75rem 1.5rem rgb(18 38 63 / 8%);
    border-color: var(--eqm-green);
}

.footprint-expand-icon {
    opacity: .2;
    transition: opacity 90ms;
}
.footprint-card:hover .footprint-expand-icon {
    opacity: 1;
}

.footprint-total-wrap {


    margin-left: 0.75rem;
    margin-top: 1rem;
}
.footprint-total-inner {
    display: inline-block;
    align-items: center;
    color: #fff;
    background: #a4a4a4;
    border-radius: 11px;
    padding: 0.375rem 0.515rem;
    transition: all 400ms;
}
.footprint-card:hover .footprint-total-inner {
    background: var(--eqm-green);
}

.footprint-total {
    text-decoration: none !important;
}

.footprint-total-icon {
    display: none;
}


.footprint-card-show-details {

    padding-right: 0;
}
.footprint-card-show-details:hover {
    color: var(--link-color);
}

.footprint-card-dates {
    line-height: 1.4;
    font-size: .9rem;
}

.footprint-link {
    display: block;
    height: 100%;
}

.footprint-link:hover {
    text-decoration: none;
}
.footprint-link:hover .btn {
    text-decoration: underline;
}

.footprint-loader {
    position: absolute;
    z-index: 1;
    right: -53px;
    top: 1.7rem;
    color: #91bfbf;
}
</style>

